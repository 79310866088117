import { ref, watch } from '@vue/composition-api';


import packageServices from '@/services/package-services';
import store from '@/store';
import { useUtils } from '@core/libs/i18n';
import debounce from "lodash/debounce";

const { t } = useUtils()
export default function usePackageList()
{
    const userListTable = ref([])

    // Table Handlers
    const tableColumns = [
        {
            text: t('ID'),
            align: 'start',
            value: 'id',
            sortable: false,
            align: 'center',
            border: 'none'
        },
        { text: t('Package Name'), value: 'name', sortable: false, align: 'center' },
        { text: t('Price'), value: 'price', sortable: false, align: 'center' },
        { text: t('Description'), value: 'description', sortable: false, align: 'center' },
        {
            text: t('Actions'),
            value: 'actions',
            align: 'center',
            sortable: false,
        },
    ]

    const searchQuery = ref('')
    const searchText = ref('')
    const options = ref({
        // sortBy: ['id'],
        // sortDesc: [true],
        page: 1,
        itemsPerPage: 10
    })
    const totalUserListTable = ref(0)
    const loading = ref(false)
    const statusFilter = ref(null)
    const selectedTableData = ref([])

    const fetchPackages = () =>
    {
        store.set('accounts/loader', true);

        packageServices.getList(options, searchText.value, searchQuery.value).then(resp =>
        {
            if (resp.statusCode == 200)
            {
                store.set('accounts/loader', false);
                userListTable.value = resp.data.boat_owner_packages
                totalUserListTable.value = resp.data.pagination.count
            }
            loading.value = false;
        })
    }

    const onSearch = debounce(() =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchPackages();
    }, 500);

    const onChangeFilter = () =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchPackages();
    };

    watch([options], () =>
    {
        // start loading
        loading.value = true
        selectedTableData.value = []
        fetchPackages()
    })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveRole = role =>
    {
        if (role === 1) return 'Admin'
        if (role === 2) return 'Approver'
        if (role === 3) return 'Initiator'

        return 'N/A'
    }

    return {
        tableColumns,
        searchQuery,
        searchText,
        options,
        userListTable,
        statusFilter,
        totalUserListTable,
        loading,
        selectedTableData,
        fetchPackages,
        resolveRole,
        onChangeFilter,
        onSearch
    }
}
