<template>
    <div>
        <div class="d-flex justify-space-between align-center pb-5">
            <p style="font-size: 18px;"
               class="font-weight-semibold text-black">
                {{ $t('Packages') }}
            </p>
            <v-btn color="primary"
                   @click="addPackage"
                   class="me-3 text-lower owner-info-btn">
                <span>{{ $t('Add Package') }}</span>
            </v-btn>
        </div>
        <v-divider></v-divider>
        <v-card id="user-list"
                class="mt-6">
            <!-- search -->
            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <span class="font-weight-semibold text-base text-green text-color">
                        {{ $t('Packages Listing') }}
                    </span>
                    <!-- </div> -->
                </div>
                <div class="d-flex align-center pb-5">

                    <v-select v-model="searchQuery"
                              v-if="admin"
                              single-line
                              dense
                              outlined
                              :items="boatOwners"
                              variant="underlined"
                              item-text="first_name"
                              item-value="id"
                              @change="onChangeFilter()"
                              hide-details
                              :placeholder="$t('Select Boat Manager')"
                              class="user-search me-3"></v-select>

                    <v-text-field v-model="searchText"
                                  single-line
                                  dense
                                  outlined
                                  prepend-inner-icon="mdi-magnify"
                                  hide-details
                                  :placeholder="$t('Search')"
                                  @input="onSearch()"
                                  width="33px"
                                  class="user-search me-3"></v-text-field>
                    <!-- 
                    <v-select v-model="searchQuery" single-line v-if="admin" dense outlined :items="boatOwners"
                        variant="underlined" item-text="first_name" item-value="id" hide-details
                        placeholder="Select Boat Owner" class="user-list-search me-3"></v-select> -->
                </div>
            </v-card-text>
            <!-- table -->
            <v-data-table v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="userListTable"
                          :options.sync="options"
                          :footer-props="{ itemsPerPageOptions: [5, 10] }"
                          :server-items-length="totalUserListTable"
                          :loading="loading"
                          :class="{ 'light-mode': shouldApplyClass, 'dark-mode': !shouldApplyClass }"
                          class="text-no-wrap my-data-table">
                <!-- trending header -->
                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>

                <!-- id -->
                <template #[`item.id`]="{ item }"
                          v-slot:items="props">
                    <!-- <router-link class="font-weight-medium text-decoration-none"
                                 :to="{ name: 'user-form', params: { id: item.id } }"> -->
                    <div class="text-black font-size">{{ item.id }}</div>

                </template>
                <template #item.name="{ item }">
                    <div class="text-black font-size">{{ item.name }}</div>
                </template>
                <template #item.price="{ item }">
                    <div class="text-black font-size">{{ convertNumber(item.price) }}</div>
                </template>
                <template #item.description="{ item }">
                    <div class="text-black font-size column-text">{{ item.description }}</div>
                </template>

                <template #[`item.role_id`]="{ item }">
                    {{ resolveRole(item.role_id) }}
                </template>

                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                    <div class="d-flex align-center justify-center">
                        <!-- approve -->
                        <!-- v-if="item.users_verification.status !== 'approved' && item.users_verification.status !== 'rejected'" -->


                        <template bottom>
                            <v-switch v-model="item.status"
                                      inset
                                      small
                                      @click="updateUserStatus(item)"
                                      value="1">
                            </v-switch>
                        </template>

                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn @click="updatePackage(item)"
                                       icon
                                       small
                                       v-bind="attrs"
                                       color="#00B2A9"
                                       v-on="on">
                                    <v-icon size="18"
                                            class="text-green">
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Edit') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       color="#00B2A9"
                                       v-bind="attrs"
                                       @click="viewPackage(item)"
                                       v-on="on">
                                    <v-icon size="20"
                                            class="text-green">
                                        {{ icons.mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('View') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import packageServices from '@/services/package-services';
import { mdiEyeOutline } from '@mdi/js';
import darkMode from '@core/utils/modalColor';
// composition function
import router from '@/router';
import userServices from '@/services/user-services';
import usePackageList from './usePackageList';
import store from '@/store';
import decimalCurrency from '@/@core/utils/decimalCurrency';

export default {
    data: () => ({
        applicationList: '',
        boatOwners: [],
        admin: false,
        shouldApplyClass: true,
    }),
    created()
    {
        
        store.subscribe((mutation) =>
        {
            // console.log(mutation)
            if (mutation.type === 'accounts/SET_DARK_MODE')
            {
                this.shouldApplyClass = mutation.payload;
                // return {
                //     'my-class': this.shouldApplyClass // Conditionally apply 'my-class' based on 'shouldApplyClass' value
                // };
            }
        })
    },
    mounted()
    {
        let isDark = localStorage.getItem('my-active-theme');
        if (isDark == 'dark')
        {
            this.shouldApplyClass = false;
        }
        this.getAllOwners();
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData.role == 'admin')
        {
            this.admin = true;
        }
    },
    methods: {
        convertNumber(value)
        {
            return decimalCurrency.convertNumber(value);
        },
        addPackage()
        {
            router.push('/package/detail');
        },
        updatePackage(item)
        {
            router.push(`/package/detail/update/${item.id}`);
        },
        viewPackage(item)
        {
            router.push(`/package/detail/view/${item.id}`);
        },
        getAllOwners()
        {
            let data = {
                role: 'boat_owner'
            }
            userServices.getOwnerManagerList(data).then(resp =>
            {
                // console.log();
                if (resp.statusCode == 200)
                {
                    this.boatOwners = resp.data.users
                    this.boatOwners.push('All')
                    // this.boatOwners.sort((a, b) => a.first_name - b.first_name);
                    // // console.log(this.boatOwners);
                    this.boatOwners.sort(function (a, b)
                    {
                        if (a < b)
                        {
                            return -1;
                        } else if (a > b)
                        {
                            return 1;
                        } else
                        {
                            return 0;
                        }
                    });
                }
            })
        },
        updateUserStatus(item)
        {

            let url;
            let body;
            if (item.status == '1')
            {
                url = 'boat_owner_packages/activate'
                body = {
                    "id": item.id,
                    "status": "1"
                }
            }
            else
            {
                url = 'boat_owner_packages/deactivate'
                body = {
                    "id": item.id,
                    "status": "2"
                }
            }
            packageServices.updateStatus(url, body).then(resp =>
            {
                // console.log(resp);
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
                else
                {
                    let status = resp.data.boat_owner_package.status == '1' ? 'Package has been activated successfully' : 'Package has been deactivated successfully';
                    this.$swal({
                        text: `${status}`,
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        // refresh here
                    })
                }
            })
        }
    },
    setup()
    {
        const {
            userListTable,
            searchQuery,
            searchText,
            tableColumns,
            options,
            totalUserListTable,
            loading,
            selectedTableData,
            resolveRole,
            onChangeFilter,
            onSearch
        } = usePackageList()

        return {
            tableColumns,
            searchQuery,
            searchText,
            options,
            totalUserListTable,
            userListTable,
            loading,
            selectedTableData,
            resolveRole,
            onChangeFilter,
            onSearch,

            icons: {
                mdiEyeOutline
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.column-text {
    max-width: 550px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    // justify-items: center;
}

#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.text-color {
    color: #00B2A9;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}


// ::v-deep .my-class.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
// .v-data-table>.v-data-table__wrapper>table>thead>tr>td,
// .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
//     font-size: 0.875rem;
//     height: 3.125rem;
//     border-left: 1px solid #efeded !important;
// }


::v-deep .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}


::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

// ::v-deep .my-class.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgba(94, 86, 105, 0.14);
//     border-right: 1px solid #efeded;
// }

// ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr:first-child>td {
//     border-left: none !important;
// }
</style>
